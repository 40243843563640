import { eventEmiter, rxPageSaved } from '../rxState';
import { graphQlCall } from '../../graphql/utils';
import QUERIES from '../../graphql/queries';

export const save = async (state) => {

    let currentPage = state.currentPage;
    if (!currentPage || currentPage === '') {
        currentPage = 'optin';
    }

    try {
        await graphQlCall({
            queryTemplateObject: QUERIES.SAVE_PAGE_MUTATION,
            headerType: "USER-AUTH",
            values: {
                id: state.pageId,
                data: JSON.stringify(state.blocks),
                pageName: currentPage,
                exitpopupdata: JSON.stringify(state.exitModalData),
                exitpopupenabled: state.exitModalEnabled,
            }
        });
        eventEmiter.next({
            type: 'add-alert',
            payload: {
                type: 'success',
                text: 'Save successfully',
            },
        });
    } catch (error) {
        eventEmiter.next({
            type: 'add-alert',
            payload: {
                type: 'error',
                text: 'Error during Save',
                description: error.message,
            },
        });
    }

    rxPageSaved.next(true);
};
