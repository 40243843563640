export const FETCH_PRODUCTS = {
    operationName: "afGetAllProducts",
    query:
      `query afGetAllProducts{
        afGetAllProducts {
            id
            name
            prices {
              id
              currency
              unit_amount
            }
        }
      }`,
  }