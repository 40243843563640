import { cloneDeep } from 'lodash';

export const getLastId = (blocks: any[], lastId: number = 0) => {
  let currentLastId = lastId;
  blocks.forEach(block => {
    if (block.id > currentLastId) {
      currentLastId = block.id;
    }
    if (block.children?.length) {
      currentLastId = getLastId(block.children, currentLastId);
    }
  });
  return currentLastId;
}

export const setIdsOrder = (blocks: any[], lastId:number = 0) => {
  let currentId = lastId;
  let newBlocks = cloneDeep(blocks);
  newBlocks = blocks.map(block => {
    let newBlock = cloneDeep(block);
    currentId++;
    newBlock.id = currentId;
    if (newBlock.children?.length) {
      const result = setIdsOrder(block.children, currentId);
      currentId = result.lastId;
      block.children = result.blocks;
    }
    return newBlock;
  });
  return {
    blocks: newBlocks,
    lastId: currentId
  };
}