import React, {useEffect, useState} from 'react';
import {ContainerFlat} from './Styles'
import DatePicker from '../Components/Common/DatePicker/DatePicker';
import { eventEmiter } from '../rx/rxState';

const AttributeDate = (props) => {
  const [value, setValue ] = useState(props.attribute.value);

  const onChange = ( value) => {
    let attr = props.attribute;
    props.onChange(attr.id, value);
    setValue( value );

    eventEmiter.next({
        type: 'update-view'
      })
  };

  return (
    <ContainerFlat>
      <div className='attribute-control'>
        <DatePicker label={props.attribute.displayName} onChange={onChange} value={value}/>
      </div>
    </ContainerFlat>
  );
};

export default AttributeDate;
