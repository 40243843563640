import React, { useState } from 'react';
import { classnames } from 'utils/functions';
import { FieldProps, fieldWrapper } from '../FieldWraper/FieldWraper'
import style from './Input.module.scss';


export interface IProps extends FieldProps {
  type?: 'text' | 'password' | 'number',
  placeholder?: string;
  autoComplete?: 'on' | 'off'
  maxLength?: number; 
} 
export const Input: React.FC<IProps> = ({onChange, value, onBlur, name, type = 'text', placeholder, error, autoComplete, maxLength}) => {
  const [errorLength, setErrorLength] = useState(false);
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const test  = e.target.value;
    
    if (maxLength !== undefined && test.length > maxLength) {
      setErrorLength(true);
    } else {
      setErrorLength(false);
      if (onChange) {
        onChange(e);
      }
    }
  };
  return (<div className={style.root}>
    <input
      type={type}
      onChange={handleChange}
      value={value}
      onBlur={onBlur}
      name={name}
      placeholder={placeholder}
      className={(error || errorLength) ? style.error : ''}
      autoComplete={autoComplete ? autoComplete : 'on'}
    />
    {errorLength && maxLength && <div className={style.errorText}>Maximum characters {maxLength}</div>}
  </div>)

}

export const FormInput = fieldWrapper(Input, {changeEvent: true});