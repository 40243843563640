import React, { useState } from 'react';
import s from './TextArea.module.scss';

interface IProps {
  value: string;
  onChange: (newValue: string) => void;
  label: string;
  hasErrors?: boolean;
  height?: number;
  autoComplete?: 'on' | 'off';
  maxLength?: number; 
}

const TextArea = (props: IProps) => {
  const [error, setError] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    if (props.maxLength !== undefined && value.length > props.maxLength) {
      setError(true);
    } else {
      setError(false);
      props.onChange(value);
    }
  };

  return (
    <div
      className={s.textareaWrapper}
      style={props.height ? { height: `${props.height}px` } : {}}
    >
      <textarea
        placeholder={props.label}
        value={props.value}
        onChange={handleChange}
        className={s.textArea + ' ' + ((props.hasErrors || error) ? s.error : '')}
        style={props.height ? { resize: 'none' } : {}}
        autoComplete={props.autoComplete ? props.autoComplete : 'on'}
      />
      {error && props.maxLength && <div className={s.errorText}>Maximum characters {props.maxLength}</div>}
    </div>
  );
};

export default TextArea;