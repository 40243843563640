export const AF_CREATE_PAYMENT_INTENT = {
  operationName: "afCreatePaymentIntent",
  query:
    `mutation afCreatePaymentIntent(
      <KEYS>
    ) {
      afCreatePaymentIntent(
        <VALUES>
      ) {
        client_secret
      }
    }`,
}