import React, { useState } from 'react';
import PopUpWrapper from './PopupWrapper';
import SignUpWorkflow from 'Components/SignUpWorkflow/SignUpWorkflow';
import { VersionStripeType } from 'types/signup';

interface IValues {
  email: string;
  password: string;
}

interface IProps {
  onSubmit: (values: IValues) => void;
  onClose: () => void;
  onSwitchToLogIn: () => void;
  versionStripePage: VersionStripeType;
  open: boolean;
}

const SignUpPopup = (props: IProps) => {
  const [isWidePopup, setWidePopup] = useState(false);
  const [hasPopupGreyColor, setGrayBackground] = useState(false);

  const handleSwitchPopUpWidth = (isWide: boolean) => {
    setWidePopup(isWide);
  };

  return props.open ? (
    <PopUpWrapper wide={isWidePopup} onClose={props.onClose} grayBackgroundColor={hasPopupGreyColor}>
      <SignUpWorkflow
        onSwitchToLogIn={() => props.onSwitchToLogIn()}
        isModal={true}
        onClose={() => props.onClose()}
        onChangePopupWidth={handleSwitchPopUpWidth}
        versionStripePage={props.versionStripePage}
        onChangeBackGroundColor={(hasGrayColor) => setGrayBackground(hasGrayColor)}
      />
    </PopUpWrapper>
  ) : null;
};

export default SignUpPopup;
