import React, { useState } from 'react';
import s from './LogInWrapper.module.scss';
import autoFunnelLogoSvg from '../../Assets/autoFunnelLogo.svg';
import SingUpAndLoginRightBlock from 'Components/SingUpAndLoginRightBlock/SingUpAndLoginRightBlock';
import LogInComponent from 'Components/LogIn/LogIn';
import { useHistory } from 'react-router-dom';

interface IValues {
  email: string;
  password: string;

}
interface IProps {
  onSwitchToSignUp?: () => void;
  isModal: boolean
}
const LogInWrapper = (props:IProps) => {
  function handleSwitchToSignUp() {
    if (props.onSwitchToSignUp) {
      
      props.onSwitchToSignUp()
    } else {
      history.push('/edit/signup')
    }

  }
  const history = useHistory();

  return (
    <div className={props.isModal ? '' : s.wrapper}>
      <div className={s.rightBgc}/>
      <div className={s.contentWithSubscription}>
        <div className={s.leftBlock}>
          <img
            src={autoFunnelLogoSvg}
            alt="logo"
            className={s.autoFunnelLogoImage}
          />
          <LogInComponent onSubmit={() => {}} onSwitchToSignUp={() => handleSwitchToSignUp()} />
        </div>
        <div className={s.rightBlock}>
          <SingUpAndLoginRightBlock />  
        </div>
      </div>
    </div>
  );
};

export default LogInWrapper;
