import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'Hooks/useSearchParams';
import { PAGECRAFT_API_URL } from 'Constants';

const mockedHtml = `<div  style="color:blue;text-align:center;">test</div>`;

const HtmlRender = () => {
  const [html, setHtml] = useState('');
  const { query } = useSearchParams();
  const screenToken = query.screenToken as string;

  const fetchAndSetHtml = async () => {
    const newHtml = await fetch(`${PAGECRAFT_API_URL}/images/screen-html?screenToken=${screenToken}`).then(res => res.text());
    setHtml(newHtml);
  }

  useEffect(() => {
    fetchAndSetHtml();
  }, [])

  return (
    <div dangerouslySetInnerHTML={{ __html: html }}>
    </div>
  )
}

export default HtmlRender;