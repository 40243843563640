import React from 'react';
import RerollComponent from 'Components/AIRerollImage/RerollComponent';

const WsTestPage = () => {
  
  return (
    <div>
      <h1>url for testing</h1>
      {/* <RerollComponent /> */}
    </div>
  )
}


export default WsTestPage;