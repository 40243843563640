import React from 'react';
import InlineStyleControls from './InlineStyleControls';
import ColorStyleControls from './ColorStyleControls';
import AlignStyleControls from './AlignStyleControls';
import SizeStyleControls from './SizeStyleControls';
import FontStyleControls from './FontStyleControls';
import BlockStyleControls from './BlockStyleControls';
import styled from 'styled-components';
import EditorStateContext from '../../Pages/EditorStateContext';
import LinksControls from './LinksControls/LinksControls';
import {
    eventEmiter
} from '../../rx/rxState';
import { thisTypeAnnotation } from '@babel/types';

const ToolbarStyle = styled.div`
  // position: fixed;
  // background: white;
  // left: 0px;
    // top: ${props => props.scrollTop}px;
  padding: 0px;
  z-index: 2001;

  .style-block {
    display: flex;
    // justify-content: space-between;
  }
`;

class TextEditorToolbar extends React.Component {
    constructor(props) {
        super(props);
        this.eventSubscription = null;
        this.state = {
            editorState: null
        }
    }

    componentDidMount() {
        this.eventSubscription = eventEmiter.subscribe({
            next: (v) => this.handleEvents(v),
        });

    }

    handleEvents(event) {
        if (event.type == 'text-attribute-changed-editor') {
            this.setState({editorState: event.payload.editorState})
        }
    }

    onChange(editorState) {
        eventEmiter.next({
            type: 'text-attribute-changed-toolbar',
            payload: {
                editorState: editorState
            }
        })
        this.setState({editorState: editorState});
    }

    onChangeBUI(editorState, styleMap) {
        this.props.onChange(editorState, {style: styleMap});
    }

    render() {
        const { date } = this.state;
        let toolbar = null;
        if (this.state.editorState != null && 
            this.props.block && 
            (this.props.block.type == 'Text' ||
            this.props.block.type == 'TextCover') ) {
            toolbar = <div className="style-block">
                <ColorStyleControls
                    block={this.props.block}
                    editorState={this.state.editorState}
                    onChange={this.onChange.bind(this)}
                />
                <FontStyleControls
                    block={this.props.block}
                    editorState={this.state.editorState}
                    onChange={this.onChange.bind(this)}
                />
                <SizeStyleControls
                    block={this.props.block}
                    editorState={this.state.editorState}
                    onChange={this.onChange.bind(this)}
                />
                <InlineStyleControls
                    block={this.props.block}
                    editorState={this.state.editorState}
                    onChange={this.onChange.bind(this)}
                />
                <AlignStyleControls
                    editorState={this.state.editorState}
                    onChange={this.onChange.bind(this)}
                />
                <BlockStyleControls
                    editorState={this.state.editorState}
                    onChange={this.onChange.bind(this)}
                />
                <LinksControls
                    editorState={this.state.editorState}
                    onChange={this.onChange.bind(this)}
                />

            </div>
        }

        return (
            <ToolbarStyle>
                {toolbar}
            </ToolbarStyle>
        );
    }
}

TextEditorToolbar.contextType = EditorStateContext;
export default TextEditorToolbar;
