import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useMemo } from 'react';

interface ReadOnlyURLSearchParams extends URLSearchParams {
  append: never;
  set: never;
  delete: never;
  sort: never;
}

export function useSearchParams() {
  const history = useHistory();
  const { search } = useLocation();

  const {query, params} = useMemo(
    () => {
      const params = new URLSearchParams(search);
      const query: any = {};
      params.forEach((index, key) => {
        if (!query[key]) {
          let value = params.getAll(key);
          query[key] = value.length === 1 ? value.pop() : value
        }
      })
      return {query, params}
    },
    [search]
  );

  const setParam = (key: string, value: any) => {
    if (value !== null && typeof value !== 'undefined' ) {
      params.set(key, value.toString());
    } else {
      params.delete(key);
    }
    history.replace({ search: params.toString() });
  }

  return {query, setParam};
}