import React, { Fragment } from 'react';
import styled from 'styled-components';
import {Block, BlockStyle} from './Block';
import StoreController from '../../StoreController';

const BlockCustomStyle = styled(BlockStyle)`

`;

class BlockCustomProxy extends React.Component {
    componentDidMount(){
        this.props.reference.current.style.cssText = this.props.styleText();

        let store = StoreController.instance();
        if(store.liveMode){
            // this.props.reference.current.innerHtml = this.props.code;
            const fragment = document.createRange().createContextualFragment(this.props.code);
            this.props.reference.current.append(fragment);
        }


      }
    render(){
        let store = StoreController.instance();
        return <BlockCustomStyle
            id={this.props.id}
            key={this.props.id}
            ref={this.props.reference}
            className={this.props.className}
            >
                { store.liveMode ?
                    <>
                    </>
                    // <div
                    //     dangerouslySetInnerHTML={{__html: this.props.code}}>
                    // </div>
                :
                    <textarea
                        style={{width: '100%', height: '100%', fontFamily: 'monospace'}}
                        onChange={this.props.onChange}
                        spellCheck={false}>
                        {this.props.code}
                    </textarea>
                }

            </BlockCustomStyle>
        }
}

export class BlockCustom extends Block{
    constructor(props){
        super(props);
        this.type = 'Custom';
        this.code = "";
    }

    pack(){
        let data = super.pack();
        //look for <script> and replace it 
        var processed = this.code.replaceAll('<script>', '<scr>')
        var processed = processed.replaceAll('</script>', '</scr>')
        data['code'] = processed;
        return data;
    }


    unpack(data){
        super.unpack(data);
        
        //look for <scr> and replace it back to <script>
        var processed = data['code'].replaceAll('<scr>', '<script>')
        var processed = processed.replaceAll('</scr>', '</script>')
        this.code = processed;
    }
    onChange(event){
        this.code = event.target.value;
    }
    renderView(){
        return (
          <Fragment key={this.id}>
            <BlockCustomProxy
                id={this.id}
                key={this.id}
                reference={this.ref}
                ref={this.proxyRef}
                styleText={this.style}
                code={this.code}
                onChange={e=>this.onChange(e)}
                className={this.className}

                >

            </BlockCustomProxy>
          </Fragment>
        )
    }
}