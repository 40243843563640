import React, { useState } from 'react';
import Button from '../../Common/Button/Button';
import s from './MailChimpSignUp.module.scss';
import { Formik, Form } from 'formik';
import { FormInput } from 'Components/Common/Inputs/Input';
import * as Yup from 'yup';
import Spinner from 'Components/Common/Spinner/Spinner';
import signUpWorkFlow from '../../../Assets/signUpWorkFlow.svg';
import { graphQlCall } from 'graphql/utils';
import QUERIES from 'graphql/queries';

interface IValues {
  email: string;
  firstName: string;
}

interface IProps {
  onSubmit: (values: IValues) => void;
  onSwitchToLogIn: () => void;
}

const SignUp = (props: IProps) => {
  const [loading, setLoading] = useState(false);

  const handleSignUp = async (values: IValues) => {
    setLoading(true);
    await graphQlCall({
      queryTemplateObject: QUERIES.ADD_TO_MAILCHIMP_LIST,
      values: {
        email: values.email,
      }
    });
    setLoading(false);
    props.onSubmit(values);
  };

  return (
    <div>
      <img src={signUpWorkFlow} alt="autoFunnel logo" className={s.logo} />
      <h2 className={s.header}>Sign Up</h2>
      <Formik
        enableReinitialize={true}
        initialValues={{ email: '', firstName: '' }}
        validationSchema={Yup.object({
          email: Yup.string()
            .matches(
              /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/,
              'Enter correct email!'
            )
            .required('Please enter email'),
          firstName: Yup.string().required(),
        })}
        validateOnBlur
        onSubmit={(values) => {
          handleSignUp(values);
        }}
      >
        <Form className={''}>
          <div className={s.signUpInputsBlock}>
            <div className={s.signUpInputWrapper}>
              <FormInput name="firstName" placeholder="First Name" />
            </div>
            <div className={s.signUpInputWrapper}>
              <FormInput name="email" placeholder="Email" />
            </div>
          </div>
          {!loading && (
            <div className={s.signUpBtn}>
              <Button type="submit">Sign Up</Button>
            </div>
          )}
          {loading && (
            <div className={s.spinnerBlock}>
              <Spinner size={36} />
            </div>
          )}
        </Form>
      </Formik>
      <div className={s.signUpBottomText}>
        Already have an account?{' '}
        <span onClick={() => props.onSwitchToLogIn()}>Log In</span>
      </div>
      <div className={s.signUpBottomSmallText}>
        By clicking "Sign Up" you agree to PageCraft’s
        <a href="https://cdn.autofunnel.ai/PageCraftLLCPrivacy.html">Privacy Policy</a> and
        <a href="https://cdn.autofunnel.ai/PageCraftLLCtos.html">Terms of Service</a>.
      </div>
    </div>
  );
};

export default SignUp;
