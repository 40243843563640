import React from 'react';
import s from './SingUpAndLoginRightBlock.module.scss';
import autoCheckout from '../../Assets/singUpAndLoginRightBlock/autoCheckout.svg';
import autoLeadPages from '../../Assets/singUpAndLoginRightBlock/autoLeadPages.svg';
import autoSalesCopy from '../../Assets/singUpAndLoginRightBlock/autoSalesCopy.svg';
import autoUpsells from '../../Assets/singUpAndLoginRightBlock/autoUpsells.svg';
import center from '../../Assets/singUpAndLoginRightBlock/center.svg';

const SingUpAndLoginRightBlock = () => {
  return (
    <div className={s.wrapper}>
      <div className={s.content}>
        <div className={s.contentCenterImgBlock}>
            <img src={center} alt="center logo" />
        </div>
        <div className={s.contentItem}>
            <img src={autoCheckout} alt="auto checkout img" />
            <div>
                Auto Sales Copy
            </div>
        </div>
        <div className={s.contentItem}>
            <img src={autoLeadPages} alt="auto lead pages img" />
            <div>
                Auto Lead Pages
            </div>
        </div>
        <div className={s.contentItem}>
            <img src={autoSalesCopy} alt="auto sales copy img" />
            <div>
                Auto Checkout
            </div>
        </div>
        <div className={s.contentItem}>
            <img src={autoUpsells} alt="auto upsells img" />
            <div>
                Auto Upsells
            </div>
        </div>
      </div>
    </div>
  )
}

export default SingUpAndLoginRightBlock;
