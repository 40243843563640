import { EditorState } from 'draft-js';
import { FontFamilies } from '../Components/TextEditor/FontFamilies';

class FontManager{
    loadedFonts: { [key: string]: number[] } = {};

    private static instance: FontManager;
    private constructor() { }

    public static getInstance(): FontManager {
        if (!FontManager.instance) {
            FontManager.instance = new FontManager();
        }

        return FontManager.instance;
    }
    public detectAndLoadCustomFont( editorState: EditorState){
        const content = editorState.getCurrentContent();
        const blockMap = content.getBlockMap();
        blockMap.forEach( (block) => {
            // console.log( block?.getText());

            block?.findStyleRanges(
                (value)=>{ 
                    const styles = value.getStyle().toArray();
                    let font = "";
                    let weight = 400;                    
                    styles?.forEach((style) => {

                        // console.log('style: ', style);
                        if(style.startsWith('font-')){
                            font = style.replace('font-', '');
                        }
        
                        if(style.startsWith('weight-')){
                            weight = parseInt(style.replace('weight-', ''));
                        }
                    });
                    if(font){
                        //might be a good idea to push this one in to loadFont function 
                        let loadedWeights = this.loadedFonts[font];
                        if(!loadedWeights){
                            loadedWeights = [weight];
                            this.loadedFonts[ font ] = loadedWeights;

                            this.loadFont( font, weight );
                        }
                        else if(!loadedWeights.includes( weight )){
                            loadedWeights.push( weight );
                            this.loadedFonts[ font ] = loadedWeights;
                            this.loadFont( font, weight );
                        }
                    }
                    return true;
                }, 
                (s:number, e:number)=>{
                    // console.log(s, e)
                }
            );



        });
    }

    public loadFont( fontName: string, weight?: number){

        //search Real font name based on Label;
        const font = FontFamilies.find( (font) => {return font.value === fontName} );
        const fontUrlName = font?.label.replace(' ', '+');

        let elementId = 'font-'+fontName;
        let fontWeight = '';
        if(weight && weight != 0){
            fontWeight = ':wght@'+weight
            elementId += ':'+weight;
        }
        
        if(!document.getElementById(elementId)) {

            var link = document.createElement('link');
            link.id = elementId;
            link.rel = 'stylesheet';
            link.href = `https://fonts.googleapis.com/css2?family=${fontUrlName}${fontWeight}&display=swap`;
            document.head.appendChild(link);
        }        
    }
}

export default FontManager;