import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { eventEmiter } from './rxState';
import RxStorage from './rxStorage';
import { loadPage } from './actions/rxLoadPage';
import { changePageName } from './actions/rxChangePageName';
import { deletePage } from './actions/rxDeletePage';
import { addPage } from './actions/rxAddPage';
import { duplicatePage } from './actions/rxDuplicatePage';
import { save } from './actions/rxSave';
import { addAlert, removeAlert } from './actions/rxAlertsActions';
import { addHistory, undoHistory, redoHistory } from './actions/rxHistoryActions';
import { saveSection } from './actions/rxSaveSection';
import { fetchSectionsMenu } from './actions/rxFetchSectionsMenu';
import { sendUserEvent } from './actions/rxUserEvent';
import { switchView, switchExitEnabled } from './actions/rxExitModal';

const RxComponent = (props) => {
  let store;

  const history = useHistory();

  const handleEvent = (event) => {
    switch (event.type) {
      case 'load-page':
        loadPage(
          {
            pages: store.get('pages'),
            navigation: store.get('navigation'),
          },
          event.payload
        );
        break;
      case 'change-page-name':
        changePageName(store.all(), event.payload);
        break;
      case 'delete-page':
        deletePage(store.all(), event.payload);
        break;
      case 'add-page':
        addPage(store.all(), event.payload);
        break;
      case 'duplicate-page':
        duplicatePage(store.all(), event.payload);
        break;
      case 'save-page':
        save(store.all());
        break;
      case 'add-alert':
        addAlert(store.all(), event.payload);
        break;
      case 'remove-alert':
        removeAlert({ alerts: store.get('alerts') }, event.payload);
        break;
      case 'add-history':
        addHistory(store.all(), event.payload);
        break;
      case 'undo':
        undoHistory(store.all(), event.payload);
        break;
      case 'redo':
        redoHistory(store.all(), event.payload);
        break;
      case 'redirect':
        history.replace({ pathname: event.payload });
        break;
      case 'save-section':
        saveSection(store.all(), event.payload);
        break;
      case 'get-side-menu':
        fetchSectionsMenu();
        break;
      case 'send-user-event':
        sendUserEvent(event.payload);
        break;
      case 'switch-exit-enabled':
        switchExitEnabled(store.all(), event.payload);
        break;
      case 'switch-exit-view':
        switchView(store.all(), event.payload);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    store = new RxStorage();
    store.init();
    let subscription = eventEmiter.subscribe({
      next: (event) => {
        if (event && event.type) {
          handleEvent(event);
        }
      },
    });
    return () => {
      subscription.unsubscribe();
      store.destroy();
    };
  }, []);

  return null;
};

export default RxComponent;
