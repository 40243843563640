import React from 'react';
import s from './PrimaryButton.module.scss'

function PrimaryButton(props) {
    const onClick = (e)=>{
        if(props.onClick){
            props.onClick(e);
        } 
    }

    return (
        <div className={s.container}>
            <button style={{width: `${props.width}px`, height: `${props.height}px`,
                fontSize: `${props.fontSize}px`}}
                    className={s.button}
                    onClick={onClick}>
                {props.image && <img src={props.image}/>}
                {props.name}
            </button>
        </div>
    );
}

export default PrimaryButton;