export const FETCH_FUNNEL_PAGES_QUERY = {
  operationName: "fetchFunnelPages",
  query:
    `query fetchFunnelPages(
      $funnelId: String!
    ) {
      fetchFunnelPages(
        funnelId: $funnelId
      ) {
        _id
        name
        projectId
        status
      }
    }`,
}