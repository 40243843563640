import styled from 'styled-components';

const StyledSideMenu = styled.div`
  color: #fff;
  background: #000;
  height: calc(100% - 80px);
  width: max-content;
  display: grid;
  grid-template-columns: 80px max-content;
  position: relative;
  z-index: 2000;
  height: calc(100vh - 60px); 
  overflow-y: auto;
  
  .blankPageArrow {
    position: absolute;
    top: 46%;  
    left: 13%;
    transform: translate(-50%, -50%);
    animation: slide 1.5s ease-in-out infinite;
  }

  @keyframes slide {
    0%,
    100% {
      transform: translate(0, 0);
    }

    50% {
      transform: translate(20px, 0);
    }
  }

  .placeholderContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .blankPageText {
    margin-top: 25px;
    font-family: Roboto, sans-serif;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    line-height: 23px;
    color: #FFFFFF;
  }
  
  .blankPageButton {
    position: absolute;
    bottom: 30px;
  }

  .menu-item {
    cursor: grab;
    padding: 10px;
    height: 70px;
    background: #000;

    .image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      margin: 0 auto;

      >span {
        text-align: center;
        font-size: 10px;
      }

      >img {
        width: 100%;
      }
    }

    .label {
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 11px;

    }

    &:hover {

      .image {
        >path{
          fill: #DA4567;
        }
        
        >span {
          color: #ddd;
        }
      }

      .label {
        color: #ddd;
      }
    }

    &.active {
      background: #2b2b2b;
    }
  }

  .sub-menu {
    background: #2b2b2b;
    overflow-y: auto;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    max-height: calc(100vh - 56px);
    transition: width .5s;
    width: 0;

    &.active {
      width: 470px;
    }
    .submenu-items-wrapper{
      display: grid;
      grid-template-columns: auto auto;
      grid-template-rows: auto;
      grid-auto-rows: max-content;
    }
    .submenu-item {
      padding: 10px 0px 10px 15px;
      &:nth-child(2n) {
        padding-left: 0;
      }
      &.devider {
        padding-top: 20px;
        padding-bottom: 0px;
        
        .label {
          margin:0;
          padding:0;
          color: #fff;
          text-align: left;
        }
      }

      &.action {
        cursor: pointer;

        .image {
          // display: flex;
          // align-items: center;
          // justify-content: center;
          width: 210px;
          height: 100%;

          >span {
            font-size: 10px;
          }

          >img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .label {
          text-align: center;
        }

        &:hover {

          .image {

            >span {
              color: #ddd;
            }
          }

          .label {
            color: #ddd;
          }
        }
      }
    }
  }

  .hide-sub-menu {
    cursor: pointer;
    
    position: absolute;
    right: -10px;
    top: calc(50% - 20px);
    width: 10px;
    height: 40px;
    z-index: 1008;

    .content {
      position: relative;

      .center {
        background: #2b2b2b;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15px;
        height: 40px;
      }

      .angle {
        border: transparent 10px solid;
        position: absolute;
        width: 0;
        height: 0;

        &.top {
          border-left: 15px #2b2b2b solid;
          top: -10px;
          left:0;
        }

        &.bottom {
          border-left: 15px #2b2b2b solid;
          bottom: -10px;
          left:0;
        }
      }
    }
  }
`;

export default StyledSideMenu;