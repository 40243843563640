export const ADD_STATISTIC_EVENT = {
  operationName: "addStatisticEvent",
  query:
    `mutation addStatisticEvent(
      $funnelId: String!
      $type: String!
      $fingerprint: String!
      $value: Float!
    ) {
      addStatisticEvent(
        funnelId: $funnelId
        type: $type
        fingerprint: $fingerprint
        value: $value
      ) {
        message
      }
    }`,
}