import React, { useState } from 'react';
import s from './singUpWrapper.module.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { GOOGLE_TAG } from 'Constants';
import SignUpWorkflow from 'Components/SignUpWorkflow/SignUpWorkflow';
import TagManager from 'react-gtm-module';
import { VersionStripeType } from 'types/signup';


TagManager.initialize({
  gtmId: GOOGLE_TAG,
  dataLayer: {
    events: {
      signed_up: 'signed_up',
    }
  }
});
interface IProps {
  versionStripePage: VersionStripeType;
}
const SingUpWrapper = (props:IProps) => {
  const history = useHistory();
  const { search } = useLocation();
  
  const [hasPopupGreyColor, setGrayBackground] = useState(false);

  const handleSwitchToLogin = () => {
    history.push('/edit/login')
  }

  return (
    <div className={s.wrapper + ' ' + (hasPopupGreyColor ? s.grayBackground : '')}>
      <SignUpWorkflow
        isModal={false}
        onSwitchToLogIn={handleSwitchToLogin}
        versionStripePage={props.versionStripePage}
        onChangeBackGroundColor={(hasGreyBackground) => setGrayBackground(hasGreyBackground)}
        />
    </div>
  );
};

export default SingUpWrapper;
