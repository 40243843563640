import { EditorState, RichUtils, Modifier, SelectionState } from 'draft-js';

export const applyStyle = (block, editorState, appliedStyle, styleMap) =>{

    const currentContent = editorState.getCurrentContent();
    const firstBlock = currentContent.getBlockMap().first();
    const lastBlock = currentContent.getBlockMap().last();
    const firstBlockKey = firstBlock.getKey();
    const lastBlockKey = lastBlock.getKey();
    const lengthOfLastBlock = lastBlock.getLength();

    let selection = editorState.getSelection();
    let nextEditorState = editorState;

    if (block && block.isEditing && selection.getStartOffset() === selection.getEndOffset()) {
      //select all in case block is not in edit mode so we can apply style to the whole block
      selection = new SelectionState({
        anchorKey: firstBlockKey,
        anchorOffset: 0,
        focusKey: lastBlockKey,
        focusOffset: lengthOfLastBlock,
      });
      nextEditorState = EditorState.forceSelection(nextEditorState, selection);
    }

    //Remove all previously applies styles
    const nextContentState = Object.keys(styleMap).reduce(
      (contentState, style) => {
        return Modifier.removeInlineStyle(contentState, selection, style);
      },
      currentContent
    );

    nextEditorState = EditorState.push(
      nextEditorState,
      nextContentState,
      'change-inline-style'
    );
    return RichUtils.toggleInlineStyle(nextEditorState, appliedStyle);
}

export const getStyleChar = ( editorState ) => {
    const selectionState = editorState.getSelection();
    const blockKey = selectionState.getStartKey();
    let start = selectionState.getStartOffset();

    const currentContent = editorState.getCurrentContent();
    const currentContentBlock = currentContent.getBlockForKey(blockKey);
    const charactersList = currentContentBlock.getCharacterList().toArray();

    if (charactersList.length === start) {
      start -= 1;
    }
    return charactersList[start];
}