import {
  rxBlocks,
  rxNavigation,
  rxPages,
  rxCurrentPage,
  rxAlerts,
  rxUserName,
  rxPageId,
  rxCreationData,
  rxDragData,
  rxHistory,
  rxHistoryStage,
  rxExitModalEnabled,
  rxShowExitModal,
  rxExitModalData,
} from './rxState';

class RxStorageClass {
  init() {
    this.subscriptions = [];
    this.state = {};
    this.subscriptions.pages = rxPages.subscribe({
      next: (v) => {
        this.state.pages = v;
      },
    });
    this.subscriptions.navigation = rxNavigation.subscribe({
      next: (v) => {
        this.state.navigation = v;
      },
    });
    this.subscriptions.currentPage = rxCurrentPage.subscribe({
      next: (v) => {
        this.state.currentPage = v;
      },
    });
    this.subscriptions.userName = rxUserName.subscribe({
      next: (v) => {
        this.state.userName = v;
      },
    });
    this.subscriptions.pageId = rxPageId.subscribe({
      next: (v) => {
        this.state.pageId = v;
      },
    });
    this.subscriptions.blocks = rxBlocks.subscribe({
      next: (v) => {
        this.state.blocks = v;
      },
    });
    this.subscriptions.alerts = rxAlerts.subscribe({
      next: (v) => {
        this.state.alerts = v;
      },
    });
    this.subscriptions.dragData = rxDragData.subscribe({
      next: (v) => {
        this.state.dragData = v;
      },
    });
    this.subscriptions.creationData = rxCreationData.subscribe({
      next: (v) => {
        this.state.creationData = v;
      },
    });
    this.subscriptions.history = rxHistory.subscribe({
      next: (v) => {
        this.state.history = v;
      }
    });
    this.subscriptions.historyStage = rxHistoryStage.subscribe({
      next: (v) => {
        this.state.historyStage = v;
      }
    });
    this.subscriptions.exitModalEnabled = rxExitModalEnabled.subscribe({
      next: (v) => {
        this.state.exitModalEnabled = v;
      }
    });
    this.subscriptions.showExitModal = rxShowExitModal.subscribe({
      next: (v) => {
        this.state.showExitModal = v;
      }
    });
    this.subscriptions.exitModalData = rxExitModalData.subscribe({
      next: (v) => {
        this.state.exitModalData = v;
      }
    });
  }

  destroy() {
    for (const key in this.subscriptions) {
      this.subscriptions[key].unsubscribe();
    }
  }

  get(field) {
    return this.state[field];
  }

  all() {
    return this.state;
  }
}

export default RxStorageClass;
