import React from 'react';
import { Editor, RichUtils, CompositeDecorator, EditorState } from 'draft-js';
import styled from 'styled-components';

import EditorStateContext from '../../Pages/EditorStateContext';
import TextEditorToolbar from './TextEditorToolbar';
import {FontSizeStyleMap, FontFamilyMap, ColorStyleMap, createStyleMap, parseClass} from './StyleMaps';
import {
	eventEmiter
} from '../../rx/rxState';
import { kebabize } from '../../utils/functions';

const TextStyle = styled.div`
  // color: black;
`;

const StyledLink = styled.a`
  text-decoration: underline;
  pointer-events: auto;

  &:hover {
    text-decoration: underline;
  }
`;

const additionalStyle = {
  'SELECTION_TEXT_COLOR': {
    backgroundColor: '#CDE2FF',
  },
}

const Link = (props) => {
  const {url} = props.contentState.getEntity(props.entityKey).getData();
  return (
    <StyledLink href={url}>
      {props.children}
    </StyledLink>
  );
};

class TextEditor extends React.Component {
  constructor(props){
    super( props );
    this.eventSubscription = null;
    this.editor = React.createRef();
    this.state = {
      updateQueued : false
    }
  }

  getEditorState() {
    return this.props.content;
  }

  prepagedEditorState(editorState) {
    if(this.state.updateQueued){ //Woraround to re-render `customStyleMap` when Mobile Layout changed.
      this.setState({updateQueued:false});
      editorState = EditorState.forceSelection(editorState, editorState.getSelection());
    }

    const linkDecorator = new CompositeDecorator([
      {
        strategy: this.findLinkEntities,
        component: Link,
      }
    ]);
    return EditorState.set(editorState, {decorator: linkDecorator});;
  }

  findLinkEntities(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();
        return (
          entityKey !== null &&
          contentState.getEntity(entityKey).getType() === 'LINK'
        );
      },
      callback
    );
  }

  setEditorState(state) {
    if (!this.props.readOnly) {
      this.setState({ editorState: state });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.isMobile != this.props.isMobile){
      this.setState({updateQueued: true});
    }

    if(prevProps.selected == true && this.props.selected == false){
      if(this.toolbarEventSubscription){
        this.toolbarEventSubscription.unsubscribe();
      }
    }
    if(prevProps.selected == false && this.props.selected == true){
      this.toolbarEventSubscription = eventEmiter.subscribe({
        next: (v) => this.handleTextToolbarEvents(v),
      });
    }
  }


  handleTextToolbarEvents(event){
    if(event.type == 'text-attribute-changed-toolbar'){
      this.props.onChange( event.payload.editorState );
    }
  }

  onChange(editorState) {
    eventEmiter.next({
      type: 'text-attribute-changed-editor',
      payload: {
        editorState: editorState
      }
    })
    this.props.onChange(editorState);
  }
  onTextEditorChange(editorState, styleMap) {
    this.props.onChange(editorState);
    // this.state.block.setTextEditorState(editorState, styleMap);
    // this.setState({ editorState: editorState });
  }

  blockStyleFn = (block) => {
    const type = block.getType();
    switch (type) {
      case 'unordered-list-item':
      case 'ordered-list-item':
        const blockStyles = [];
        block.findStyleRanges((item) => {
          const itemStyles = item.getStyle().toJS();
          return itemStyles.some((key) => parseClass(key, this.props.isMobile))
        }, (startCharacter) => {
          if (startCharacter === 0) {
            // Apply the same styling to the block as the first character
            block.getInlineStyleAt(startCharacter).toArray().forEach( (styleKey) => {
              blockStyles.push(`block-style-${styleKey.replace('#', '')}`);
            });
          }
        });

        return blockStyles.join(' ');
      case 'align-left-item':
        return 'align-left-item';
      case 'align-center-item':
        return 'align-center-item';
      case 'align-right-item':
        return 'align-right-item';
      default:
        break;
    }
  }

  handleKey(command, oldState) {
    const styles = ['italic', 'bold', 'underline'];
    if (styles.includes(command)) {
      this.changeInlineStyle(command.toUpperCase(), oldState);
    }
  }

  changeInlineStyle(style, oldState) {
    const newState = RichUtils.toggleInlineStyle(oldState, style);
    this.onChange(newState);
  }

  mapToStyle(styleMap) {
    let style = '';
    for (const key in styleMap) {
      if (Object.hasOwnProperty.call(styleMap, key)) {
        if (key.includes('block-style')) {
          const value = styleMap[key];
          style += `
            .${key} {
              ${Object.entries(value).map(([key, value]) => `${kebabize(key)}: ${value};`)}
            }
          `
        }
      }
    }
    return style;
  }

  render() {
    const sizeMap = FontSizeStyleMap(this.props.isMobile);
    const styleMap = createStyleMap(
      {...sizeMap, ...FontFamilyMap, ...additionalStyle},
      this.props.content,
      this.props.isMobile
    );

    return (
      <TextStyle
        className={this.props.liveMode || !this.props.readOnly ? '' : 'NoPointerEvents'}
        readOnly={this.props.readOnly}
      >
        <style>{this.mapToStyle(styleMap)}</style>

        <Editor
          style={{ pointerEvents: 'none' }}
          ref={this.editor}
          stripPastedStyles={true}
          editorState={this.prepagedEditorState(this.props.content)}
          onChange={this.onChange.bind(this)}
          spellCheck={true}
          customStyleMap={styleMap}
          blockStyleFn={this.blockStyleFn}
          readOnly={this.props.readOnly}
          handleKeyCommand={this.handleKey.bind(this)}
          onBlur={this.props.onBlur}
          onKeyDown
        />
      </TextStyle>
    );
  }
}

TextEditor.contextType = EditorStateContext;

export default TextEditor;