export const CREATE_BOOK_REQUEST = {
  operationName: "createBookRequest",
  query:
    `mutation createBookRequest(
      $title: String!
      $description: String!
      $tone: String!
    ) {
      createBookRequest(
        title: $title
        description: $description
        tone: $tone
      ) {
        _id
      }
    }`,
}