export const REFRESH_TOKEN = {
  operationName: "refreshToken",
  query:
    `query refreshToken(
      $refreshToken: String!
    ) {
      refreshToken(
        refreshToken: $refreshToken
      ) {
        token
        refreshToken
      }
    }`,
}