export const CREATE_ZAPPIER_CUSTOMER = {
  operationName: "createZuppierCustomer",
  query:
    `mutation createZuppierCustomer(
      <KEYS>
    ) {
      createZuppierCustomer(
        <VALUES>
      ) {
        _id
      }
    }`,
}