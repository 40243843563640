export const AF_CREATE_SETUP_INTENT = {
  operationName: "afCreateSetupIntent",
  query:
    `mutation afCreateSetupIntent(
      $priceId: String!
      $lm_data: String
    ) {
      afCreateSetupIntent(
        priceId: $priceId
        lm_data: $lm_data
      ) {
        client_secret
      }
    }`,
}