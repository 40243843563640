import React, { useState } from 'react';
import s from './Toolbar.module.scss';
import ToolbarBlocks from '../../Toolbar/ToolbarBlocks';
import { ReactComponent as HomeIcon } from '../../../Assets/Home.svg';
import { ReactComponent as RocketSvg } from '../../../Assets/rocketDark.svg';
import { CONSULT_URL } from 'Constants';
import { eventEmiter, rxBookNeedSave } from 'rx/rxState';
import { TOOLBAR_ID } from '../../../Components/Toolbar/ToolbarPanel';

interface ToolbarProps {
  showOnlyHomeBtn?: boolean;
}

const Toolbar: React.FC<ToolbarProps> = ({ showOnlyHomeBtn }) => {
  const handleRedirectToHomePage = () => {
    const homeLink = `${CONSULT_URL}/console/books`;
    window.open(homeLink, '_self');
  };
  const onSaveClick = () => {
    rxBookNeedSave.next(true)
  };

  if (showOnlyHomeBtn) {
    return (
      <div className={s.toolbarWrapper}>
        <div className={s.homeBlock} onClick={() => handleRedirectToHomePage()}>
          <HomeIcon />
        </div>
      </div>
    );
  } else
    return (
      <div className={s.toolbarWrapper}>
        <div className={s.homeBlock} onClick={() => handleRedirectToHomePage()}>
          <HomeIcon />
        </div>
        <div className={s.toolbarBlocks} id={TOOLBAR_ID}>
          <ToolbarBlocks />
       </div>
        <div className={s.saveButton} onClick={()=>onSaveClick()}>
              Save
          </div>
      </div>
    );
};
export default Toolbar;
