export const CREATE_PAGE_FROM_TEMPLATE_MUTATION = {
  operationName: "createPageFromTemplate",
  query:
    `mutation createPageFromTemplate(
      <KEYS>
    ) {
      createPageFromTemplate(
        <VALUES>
      ) {
        _id
        name
        description
        enabled
        visited
        conversion
        archived
        general {
          _id
        }
        headerScript
        footerScript
        order {
          id
          previous
          next
        }
      }
    }`,
}