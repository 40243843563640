import React, { useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { AUTH_USER_TOKEN_KEY } from '../Constants';
import { username } from '../utils/api';
import StyledLogin from '../Styled/StyledLogin';
import { ReactComponent as MechLogoIcon } from '../Assets/mechLogoIcon.svg';
import { ReactComponent as MechLogoText } from '../Assets/mechLogoTextBlack.svg';
import { ENVIRONMENT } from '../Constants';
import LoginPopup from '../Components/Popups/LogIn';
import { graphQlCall } from '../graphql/utils';
import QUERIES from '../graphql/queries';

const PageLogin = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const formRef = useRef(null);
  const { user, productId, page } = useParams();

  function getData(form) {
    const formData = new FormData(form);
    const values = {};

    // iterate through entries...
    for (var [key, value] of formData.entries()) {
      values[key] = value;
    }

    return values;
  }

  /**
   * @param {SubmitEvent} e
   */
  const onFinish = async (e) => {
    e.preventDefault();
    const values = getData(e.target);
    setLoading(true);

    try {
      let authUser = await Auth.signIn(values.username, values.password);

      localStorage.setItem(
        AUTH_USER_TOKEN_KEY,
        authUser.signInUserSession.accessToken.jwtToken
      );

      const data = {
        email: values.username,
      };
      const owner = username(authUser.signInUserSession.accessToken.jwtToken);
      const user = await graphQlCall({
        queryTemplateObject: QUERIES.GET_USER_DETAILS_QUERY,
        values: {
          owner
        }
      })

      localStorage.setItem('USER_ROLE', user.role);

      if (user /*&& res.subscribed*/) {
        //TODO: uncooment that when we switch to new user

        const redirect = `/edit/edit/${user}/${productId}/${page}`;
        setTimeout(() => {
          history.replace({ pathname: redirect });
        }, 1500);
      } else {
        setLoading(false);
      }
    } catch (e) {
      alert('Something went wrong');
      console.error(e);
    }

    setLoading(false);
  };

  return (
    <StyledLogin>
      <h1>
        <div
          style={{
            display: 'flex',
            width: '350px',
            margin: 'auto',
            padding: '30px',
          }}
        >
          <MechLogoIcon
            style={{ margin: '10px', width: '80px', height: '80px' }}
          />
          <MechLogoText
            style={{
              margin: 'auto',
              marginTop: '16px',
              width: '200px',
              height: '100px',
            }}
          />
        </div>
      </h1>
      <h5>Welcome to Create!</h5>
      <h5>Create your own website today with the most productive builder!</h5>
      {ENVIRONMENT == 'LOCAL' && (
        <form onSubmit={onFinish} ref={formRef}>
          <input placeholder="Email" name="username" />
          <input placeholder="Password" name="password" type="password" />
          <button
            type="primary"
            disabled={loading}
            htmlType="submit"
            className="submit-button"
          >
            {loading ? 'loading' : 'Log in'}
          </button>
        </form>
      )}
    <LoginPopup
      onClose={() => {}}
      onSubmit={() => {}}
      onSwitchToSignUp={() => {}}
      open={true}
    />
    </StyledLogin>
  );
};

export default PageLogin;
