export const GET_PAGE_INFO_QUERY = {
  operationName: "getPageInfo",
  query:
    `query getPageInfo(
      $id: String!
    ) {
      getPageInfo(
        id: $id
      ) {
        pageName
        projectName
        projectId
        headerScript
        footerScript
        funnelHeaderScript
        funnelFooterScript
        stripePublicKey
        title
      }
    }`,
}