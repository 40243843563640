import React from 'react';
import styled from 'styled-components';
import StyleButton from './StyleButton';

import { ReactComponent as BoldOutlined } from '../../Assets/bold.svg';
import { ReactComponent as ItalicOutlined } from '../../Assets/italic.svg';
import { ReactComponent as UnderlineOutlined } from '../../Assets/underline.svg';
import { applyStyle } from './StyleControlsUtils';

const ControlStyle = styled.div`
  display: flex;
  margin-right: 10px;
`;

var INLINE_STYLES = [
  { icon: <BoldOutlined />, label: 'Bold', style: 'BOLD' },
  { icon: <ItalicOutlined />, label: 'Italic', style: 'ITALIC' },
  { icon: <UnderlineOutlined />, label: 'Underline', style: 'UNDERLINE' },
];

export default class InlineStyleControls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      styleMap: {}
    }
  }

  onToggle(inlineStyle) {
    //
    const styleName = 'font-' + inlineStyle;

    let style;

    if (inlineStyle === 'BOLD') {
      style = {
        fontWeight: 'bold'
      }
    }
    if (inlineStyle === 'ITALIC') {
      style = {
        fontStyle: 'italic'
      }
    }
    if (inlineStyle === 'UNDERLINE') {
      style = {
        textDecoration: 'underline'
      }
    }

        
    let newStyleMap = { ...this.state.styleMap };
    newStyleMap[styleName] = style;
    this.setState({
      ...this.state,
      styleMap: newStyleMap,
    })
    const nextEditorState = applyStyle( this.props.block, this.props.editorState, inlineStyle, newStyleMap);
    this.props.onChange(nextEditorState, newStyleMap, styleName);

    this.setState({});
  }

  render() {
    let currentStyle;
    if (this.props.editorState) {
      currentStyle = this.props.editorState.getCurrentInlineStyle();
    }
    return (
        <ControlStyle>
          {INLINE_STYLES.map((type) => (
            <StyleButton
              key={type.label}
              active={currentStyle.has(type.style)}
              icon={type.icon}
              onToggle={this.onToggle.bind(this)}
              style={type.style}
              tooltip={type.label}
            />
          ))}
        </ControlStyle>
    );
  }
}
