import React, {ButtonHTMLAttributes, PropsWithChildren} from 'react';
import { classnames } from 'utils/functions';
import style from './Button.module.scss';


interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: 'primary' | 'secondary' | 'accent' | 'attribute';
  size?: 'small' | 'regular' | 'attribute'
  borderRadius?:string
}

const Button: React.FC<PropsWithChildren<IProps>> = ({children, color = 'primary', size = 'regular', borderRadius, ...rest}) => {
    const colors = {
      primary: style.primaryColor,
      secondary: style.secondaryColor,
      accent: style.accentColor,
      attribute: style.attributeColor
    }

    const sizes = {
      small: style.small,
      regular: style.regular,
      attribute: style.attribute
    }

    return (
      <button
        className={classnames(style.button, colors[color], sizes[size])}
        style={borderRadius ? {borderRadius:borderRadius} : {}}
        {...rest}
      >
        {children}
      </button>
    );
}

export default Button;