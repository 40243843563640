export const CREATE_BOOK_MUTATION = {
  operationName: "createBook",
  query:
    `mutation createBook(
      <KEYS>
    ) {
      createBook(
        <VALUES>
      ) {
        _id
        title
        description
        tone
        chapters{
          title
          text
          comments
        }
        audience
        structure
      }
    }`,
}