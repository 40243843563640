import React  from 'react';
import s from './LoadingSpinner.module.scss';


const LoadingSpinner = () => {
    return (
        <div className={s.loader}>
            <div className={s.line}></div>
            <div className={s.line}></div>
            <div className={s.line}></div>
            <div className={s.line}></div>
        </div>
    )
}

export default LoadingSpinner;