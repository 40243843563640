import React, { useState } from 'react';
import style from './stripePlansPage.module.scss';
import StripePurchase from '../StripePurchase/StripePurchase';
import StripePlans from '../../Components/SignUpWorkflow/StripePlans/StripePlans'

interface IProps {
  discount?:boolean
}

const StripePlansPage = (props:IProps) => {
  const [priceId, setPriceId] = useState('')
  const [checkout, setCheckout] = useState( false )
  const [priceData, setPriceData] = useState<{
    price: number;
    priceGap: string;
} | null>(null)

  const handleCustomStripe = async (priceId: string, priceData?:{price:number, priceGap:string}) =>{
    if (priceData) {
      setPriceData(priceData);
    }
    setPriceId( priceId )
    setCheckout( true );
  }
  
    return (
      <div className={style.pageWrapper}>
        { checkout ?
          <StripePurchase
          email = {''}
          name = {''} 
          priceId = {priceId}
          priceData={priceData}
        />
        :
        <StripePlans 
          versionPage='custom' 
          discount={props.discount}
          onSubmit={handleCustomStripe}/>
        }
      </div>
    );
  };
  
  export default StripePlansPage;