import React, {useEffect, useState} from 'react';
import {ContainerFlat} from './Styles'
import RadioSwitch from '../Components/Common/RadioSwitch/RadioSwitch';
import { eventEmiter } from '../rx/rxState';

const AttributeSwitch = (props) => {
  const [value, setValue ] = useState(props.attribute.value);

  const onChange = (value) => {
    let attr = props.attribute;
    props.onChange(attr.id, value);
    setValue( value );
    eventEmiter.next({
        type: 'update-view'
    })
  
  };

  return (
    <ContainerFlat style={{width: (props.attribute.options.length * 30 +20) +'px'}}>
      <div className='attribute-control'>
         <RadioSwitch 
            value={value} 
            onChange={onChange} 
            options={props.attribute.options}/>
      </div>
    
    </ContainerFlat>
  );
};

export default AttributeSwitch;
