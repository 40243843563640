export const CREATE_UPSELL_PAYMENT_INTENT = {
  operationName: "createUpsellPaymentIntent",
  query:
    `mutation createUpsellPaymentIntent(
      <KEYS>
    ) {
      createUpsellPaymentIntent(
        <VALUES>
      ) {
        message
      }
    }`,
}