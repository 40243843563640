import React, { useEffect, useState } from 'react';
import MailChimpSignUp from './MailChimpSignUp/MailChimpSignUp';
import StripePlans from './StripePlans/StripePlans';
import SignUpCognito from './SignUpCognito/SignUpCognito';
import { IUserCreds, ICognitoValues } from 'types/signup';
import { CONSULT_URL, AUTH_USER_TOKEN_KEY, GOOGLE_TAG } from 'Constants';
import { Auth } from 'aws-amplify';
import { username } from 'utils/api';
import TagManager from 'react-gtm-module';
import { useSearchParams } from 'Hooks/useSearchParams';
import { VersionStripeType } from 'types/signup';
import StripePurchase from '../StripePurchase/StripePurchase';
import Spinner from 'Components/Common/Spinner/Spinner';
import { graphQlCall } from 'graphql/utils';
import QUERIES from 'graphql/queries';
import s from './SignUpWorkflow.module.scss';

interface IProps {
  onSwitchToLogIn: () => void;
  onClose?: () => void;
  isModal: boolean;
  versionStripePage: VersionStripeType;
  onChangePopupWidth?: (wide: boolean) => void;
  onChangeBackGroundColor: (greyBackground: boolean) => void;
}

TagManager.initialize({
  gtmId: GOOGLE_TAG,
  dataLayer: {
    events: {
      signed_up: 'signed_up',
    }
  }
});

const SignUpWorkflow = (props: IProps) => {
  const [step, setStep] = useState(1); //used to be 1
  const [firstName, setFirstName] = useState('');
  const [customerEmail, setEmail] = useState('');
  const [stripeClientId, setStripeClientId] = useState('');

  const [loading, setLoading] = useState(false)
  const [priceId, setPriceId] = useState('')

  const [priceData, setPriceData] = useState<{
    price: number;
    priceGap: string;
  } | null>(null)

  const {
    query: { session_id, setup_intent, name, email },
  } = useSearchParams();

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  useEffect(() => {
    if (session_id) {
      handleStartCognitoSignUp();
    }
  }, [session_id])

  useEffect(() => {
    if (setup_intent) {
      setLoading(true)
      handleStripeSubscriptionCreation();
    }
  }, [setup_intent])

  const handleStripeSubscriptionCreation = async () => {
    const stripeResponse = await graphQlCall({
      queryTemplateObject: QUERIES.STRIPE_SETUP_SUBSCRIPTION_MUTATION,
      values: {
        setup_intent: setup_intent,
        name: name,
        email: email,
        lm_data: window.lm_data,
      }
    });

    setStep(5);
    // if (stripeResponse) {
    //   cognitoSetup(stripeResponse.name, stripeResponse.email, stripeResponse.id)
    // }
    setLoading(false)
  }

  const handleMailchimpCompleted = (values: IUserCreds) => {
    if (props.onChangePopupWidth) {
      props.onChangePopupWidth(true);
    }
    props.onChangeBackGroundColor(true);
    setEmail(values.email);
    setFirstName(values.firstName);
    setStep(2);
  };

  const handleCustomStripe = async (priceId: string, priceData?: { price: number, priceGap: string }) => {
    if (priceData) {
      setPriceData(priceData);
    }
    setPriceId(priceId)
    setStep(4);
  }

  const handleStartCognitoSignUp = async () => {
    setStep(3);
    const stripeResponse = await graphQlCall({
      queryTemplateObject: QUERIES.STRIPE_GET_CUSTOMER_FROM_SESSION_QUERY,
      values: {
        sessionId: session_id,
        name,
        email,
      }
    })

    if (stripeResponse) {
      cognitoSetup(stripeResponse.name, stripeResponse.email, stripeResponse.id)
    }
  }

  const cognitoSetup = (name: string, email: string, id: string) => {
    if (name && name.length) {
      const nameComponents = name.split(' ')
      setFirstName(nameComponents[0]);
    }
    if (email) {
      setEmail(email);
    }
    if (id) {
      setStripeClientId(id);
    }
  }

  const signUpComplete = () => {
    window.open(`${CONSULT_URL}/edit/generate`, "_self");
  }

  const handleCognitoFormSubmit = async (userCreds: ICognitoValues) => {
    const { email, password, firstName, lastName } = userCreds;
    const name = `${firstName} ${lastName}`;

    try {
      const cognitoResult = await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          name,
          // stripeClientId,
        },
      });
      const { userSub } = cognitoResult;

      await graphQlCall({
        queryTemplateObject: QUERIES.CREATE_USER_MUTATION,
        values: {
          email,
          name,
          stripeClientId,
        }
      });
      const cognitoResponse = await Auth.signIn(userCreds.email, userCreds.password);
      Document.prototype.cognitoUser = cognitoResponse;

      if (cognitoResponse.challengeName === "NEW_PASSWORD_REQUIRED") {
        const email = cognitoResponse.challengeParam.userAttributes.email;
        localStorage.setItem('USER_EMAIL', email);
        window.open(`${CONSULT_URL}/console/signup/${email}`, "_self");
        return;
      }

      const owner = username(cognitoResponse.signInUserSession.accessToken.jwtToken);
      const user = await graphQlCall({
        queryTemplateObject: QUERIES.GET_USER_DETAILS_QUERY,
        values: {
          owner
        }
      });

      localStorage.setItem('USER_ID', user._id);
      localStorage.setItem('USER_ROLE', user.role);
      if (user.subdomain) {
        localStorage.setItem('USER_SUBDOMAIN', user.subdomain);
      }
      localStorage.setItem(
        AUTH_USER_TOKEN_KEY,
        cognitoResponse.signInUserSession.accessToken.jwtToken
      );
      localStorage.setItem('USER_EMAIL', userCreds.email);

      TagManager.dataLayer({
        dataLayer: {
          event: 'signed_up'
        },
      });

      signUpComplete();
    } catch (err: any) {
      console.log('cognito error', err);
      if (err.code === 'UsernameExistsException') {
        props.onSwitchToLogIn();
      }
    }
  }

  return (


    <div style={{ width: '100%' }}>
      {loading &&
        <Spinner size={100}></Spinner>
      }
      {!loading &&
        <>
          {step === 1 && (
            <MailChimpSignUp
              onSubmit={(values) => handleMailchimpCompleted(values)}
              onSwitchToLogIn={() => props.onSwitchToLogIn()}
            />
          )}
          {step === 2 && (
            <div>
              <StripePlans
                email={customerEmail}
                versionPage={props.versionStripePage}
                onSubmit={handleCustomStripe}
              />
            </div>
          )}
          {step === 3 && (
            <SignUpCognito
              email={customerEmail}
              firstName={firstName}
              onSubmit={handleCognitoFormSubmit}
              onSwitchToLogIn={() => props.onSwitchToLogIn()}
            />
          )}
          {step === 4 && (
            <StripePurchase
              email={customerEmail}
              name={firstName}
              priceId={priceId}
              priceData={priceData}
            />
          )}
          {step === 5 && (
              <div className={s.container}>
              <h2 className={s.header}>Congratulations! </h2>
              <div className={s.text}>Your purchase is complete. We're thrilled to have you as a valued customer. 
              <br/><br/>Please keep an eye on your email inbox, as we will be sending you a confirmation email shortly with all the details related to your purchase.
              <br/><br/>If you have any immediate questions or require assistance, feel free to reach out to our customer support team support@autofunnel.ai.
              <br/><br/>Thank you for choosing us!</div>
              </div>  

          )}
        </>
      }
    </div>

  );
};

export default SignUpWorkflow;
