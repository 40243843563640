import styled from 'styled-components';

export const Input = styled.input`
  height: 30px;
  border-radius: 5px;
  background-color: white;
  border-width: 0px;
  padding: 5px;
`

export const Container = styled.div`
  padding: 0px 5px 5px 5px;

  .attribute-label {
    color: #828282;
    font-size: 10px;
  }
`


export const ContainerFlat = styled.div`
  display: flex;
  padding: 0px 5px 0px 5px;

  .attribute-label {
    padding: 5px;
    margin: auto;
  }
  .attribute-control {
    margin: auto;

    .ant-picker{
      border-radius:5px;
    }
  }
  .attribute-control-border-radius{
      margin: auto;
      position:relative;
      &:
      .ant-picker{
        border-radius:5px;
      }
      &:after{
        content:"";
        position:absolute;
        right:-6px;
        top:0;
        height:100%;
        width:2px;
        background-color: #D5D7DA;

      }
  }


`