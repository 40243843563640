import React, {ButtonHTMLAttributes, PropsWithChildren} from 'react';
import { classnames } from 'utils/functions';
import style from './LinkButton.module.scss';


interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: 'primary' | 'secondary';
}

const LinkButton: React.FC<PropsWithChildren<IProps>> = ({children, color = 'primary' , ...rest}) => {
    const colors = {
      primary: style.primaryColor,
      secondary: style.secondaryColor,
      accent: style.accentColor
    }

    return (
      <button
        type='button'
        className={classnames(style.button, colors[color])}
        {...rest}
      >
        {children}
      </button>
    );
}

export default LinkButton;