import { eventEmiter } from '../rxState';

export const VIEW_STATUSES = {
  IDLE: 'IDLE',
  EDITING_TEXT: 'EDITING_TEXT',
  ADDING: 'ADDING',
  DISABLED: 'DISABLED'
}

export const VIEW_TYPES = {
  POPUP: 'POPUP',
  REGULAR: 'REGULAR',
}

export const switchStatus = (payload) => {
  eventEmiter.next({
    type: 'change-view-status',
    payload,
  });
}
