import React, { useEffect, useState } from 'react';
import s from './sections.module.scss';
import { TEMPLATES_URL } from '../../../Constants';

function Sections({ sections, handleSectionClick }) {
  
  return (
    <div className={s.container}>
      {sections.map((sectionWithDevider) => {
        return (
          <div>
            <div className={s.sectionDevider}>{sectionWithDevider.devider}</div>
            <div className={s.sectionItems}>
              {sectionWithDevider.sections.map((section) => {
                return (
                  <div key={section._id} className={s.sectionItem} onClick={()=>handleSectionClick(section.data)}>
                    <img
                      src={`${TEMPLATES_URL}/${section.image}`}
                      alt="menu item"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Sections;
