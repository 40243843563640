import React, {useEffect, useState} from 'react';
import InputWithPrefix from "../Components/Common/Inputs/InputWithPrefix/InputWithPrefix";

const AttributeString = (props) => {

    const [value, setValue] = useState("");
    const [isUrlValid, setIsUrlValid] = useState(true);
    const [isInputFocused, setIsInputFocused] = useState(false);

    const urlRegex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/

    const handleSubmit = (value) => {
        // if (value.match(urlRegex)) {
            setIsUrlValid(true)
            let attr = props.attribute
            props.onChange(attr.id, value)
            // console.log('submit')
        // } else {
        //     setIsUrlValid(false)
        // }
    };

    useEffect(() => {
        setValue(props.attribute.value);
    }, [])

    return (
        <InputWithPrefix
            isInputFocused={isInputFocused}
            setIsInputFocused={setIsInputFocused}
            isErrorActive={!isUrlValid}
            width={props.attribute.width}
            label={props.attribute.displayName}
            prefix={props.attribute.prefix}
            value={value}
            handleSubmit={handleSubmit}
            setState={setValue}
        />
    )
};

export default AttributeString;