import Button from 'Components/Common/Button/Button';
import { useSearchParams } from 'Hooks/useSearchParams';
import { useEffect } from 'react';
import { ReactComponent as RocketSvg } from '../../Assets/blackRocket.svg';

import style from './OpenEditorPanel.module.scss';

interface IProps {
  onEdit: () => void;
  onSave: () => void;
}

const OpenEditorPanel = (props: IProps) => {
  const {setParam} = useSearchParams();
  
  return (
    <div className={style.root}>
      <div className={style.panel}>
        <div className={style.buttonsBlock}>
          <button
            className={style.buttonEdit}
            onClick={
              () => { props.onSave() }
              // setParam('generated', undefined)
            }
          >
            Edit
          </button>
          {/* <button
            className={style.buttonSave}
            onClick={
              () => {props.onSave()
              //setParam('generated', undefined)
            }
            }
          >
            <RocketSvg /> Save
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default OpenEditorPanel;
