export const RENAME_SUBPAGE = {
  operationName: "renameSubPage",
  query:
    `mutation renameSubPage(
      $pageId: String!
      $newName: String!
      $oldName: String!
    ) {
      renameSubPage(
        pageId: $pageId
        newName: $newName
        oldName: $oldName
      ) {
        message
      }
    }`,
}