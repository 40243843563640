import React from 'react';
import Dropdown from './AttributeDropdown';
import Time from './AttributeTime/AttributeTime';
import Color from './AttributeColor';
import Bool from './AttributeBool';
import String from './AttributeString';
import Switch from './AttributeSwitch';
import Date from './AttributeDate';
import Image from './AttributeImage/AttributeImage';

const AttributeComponent = ({attribute, key, block, onChange}) => {

  const getAttribute = (attribute, key, block, onChangeCallback) => {
    switch (attribute.type){
      case 'AttributeDropdown':
          return <Dropdown block={block} key={key} attribute={attribute} onChange={onChangeCallback}/>
      case 'AttributeColor':
          return <Color block={block} key={key} attribute={attribute} onChange={onChangeCallback}/>
      case 'AttributeString':
          return <String block={block} key={key} attribute={attribute} onChange={onChangeCallback}/>
      case 'AttributeBool':
          return <Bool  block={block} key={key} attribute={attribute} onChange={onChangeCallback}/>
      case 'AttributeTime':
        return <Time block={block} key={key} attribute={attribute} onChange={onChangeCallback}/>
      case 'AttributeSwitch':
        return <Switch block={block} key={key} attribute={attribute} onChange={onChangeCallback}/>
      case 'AttributeDate':
        return <Date block={block} key={key} attribute={attribute} onChange={onChangeCallback}/>
      case 'AttributeImage':
          return <Image block={block} key={key} attribute={attribute} onChange={onChangeCallback}/>
                                
      default:    
      return null;
    }
  }
    
  
  return (
    <>
      { getAttribute(attribute, key, block, onChange) }
    </>
  )
};

export default AttributeComponent;
