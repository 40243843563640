import React, {useState} from 'react';
import arrowUp from '../../../../Assets/arrow-up.svg';
import arrowDown from '../../../../Assets/arrow-down.svg';
import s from './Number.module.scss';

interface IProps {
    step: number;
    min: number; 
    max: number; 
    value: number;
    onChange: (value: number) => void;
    tooltip?: string;
 }

const NumberInput = (props: IProps) =>{

    const filter = (v:number)=>{
        return Math.max(Math.min(v, props.max), props.min);
    }

    const handleChange = (e: any)=>{
        props.onChange( filter(e.target.value) );
    }

    const handleUp = (e: any) => {
        e.preventDefault();
        props.onChange( filter(props.value + props.step) );
    }

    const handleDown = (e:any) => {
        e.preventDefault();
        props.onChange( filter(props.value - props.step) );
    }

    return (
    <div className={s.container}>                
        <input 
            type="number"
            min={props.min} 
            max={props.max} 
            step={props.step} 
            value={props.value} 
            onChange={handleChange}
        />
        <div className={s.nav}>
            <img
                onMouseDown={(e)=>e.preventDefault()}
                draggable={false} 
                className={s.button} 
                src={arrowUp} 
                onClick={handleUp} 
            />
            <img 
                onMouseDown={(e)=>e.preventDefault()}
                draggable={false}
                className={s.button} 
                src={arrowDown} 
                onClick={handleDown} 
            />
        </div>
    </div>)
}

export default NumberInput;