import { eventEmiter, rxSideMenu, rxSections } from '../rxState';
import { PAGECRAFT_API_URL } from '../../Constants';

export const fetchSectionsMenu = () => {
  return fetch(`${PAGECRAFT_API_URL}/section-menu`)
    .then( res=> res.json())
    .then((res) => {
      return fetch(`${PAGECRAFT_API_URL}/section`)
      .then( res=> res.json())
      .then( (res2) => {
        const sections = res2 ;
        rxSections.next(sections);
        const menu = res.items.map(item => {
          item.items = item.items.map(subitem => {
            if (subitem.type === 'section') {
              let foundSection;
              sections.forEach(section => {
                if (subitem.section === section._id) {
                  foundSection = section;
                }
              });
              subitem = {...subitem, ...foundSection};
            }
            return subitem;
          })
          return item;
        })
        rxSideMenu.next(menu);
      });

    })
    .catch((error) => {
      eventEmiter.next({
        type: 'add-alert',
        payload: {
          type: 'error',
          text: 'API fetch side menu error',
          description: error.message,
        },
      });
    });
};
