export const STRIPE_SETUP_SUBSCRIPTION_MUTATION = {
  operationName: "setupSubscription",
  query:
    `mutation setupSubscription(
      $setup_intent: String!
      $email: String!
      $name: String!
      $lm_data: String
    ) {
      setupSubscription(
        setup_intent: $setup_intent
        email: $email
        name: $name
        lm_data: $lm_data
      ) {
        name
        email
        id
      }
    }`,
}