import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import PrimaryButton from 'Components/Common/PrimaryButton/PrimaryButton';
import { FormInput } from 'Components/Common/Inputs/Input';
import Button from 'Components/Common/Button/Button';
import style from './Question.module.scss';
import { ReactComponent as ArrowRight } from '../../../Assets/icons/arrow-right.svg';
import { ReactComponent as ArrowLeft } from '../../../Assets/icons/arrow-left.svg';
import LinkButton from 'Components/Common/LinkButton/LinkButon';
import { IQuestion } from '../AIQuestionnaire';

interface IProps {
  onSubmit: (value: any) => void;
  onPrevious: () => void;
  question: IQuestion;
  answer: any;
  isLast?: boolean;
}

const Question: React.FC<IProps> = ({
  onSubmit,
  answer,
  question,
  onPrevious,
  isLast,
}) => {
  return (
    <div className={style.root}>
      <div className={style.question}>{question.question}</div>
      {question.key === 'type' ? (
        <div className={style.type}>
          <Button
            color="secondary"
            onClick={() => onSubmit({ answer: 'product' })}
          >
            Product
          </Button>
          <Button
            color="secondary"
            onClick={() => onSubmit({ answer: 'service' })}
          >
            Service
          </Button>
        </div>
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={{ answer }}
          validationSchema={Yup.object({
            answer: question.validation!,
          })}
          validateOnBlur
          onSubmit={(values) => {
            if (onSubmit) {
              onSubmit(values);
            }
          }}
        >
          <Form className={style.questionForm}>
            <div className={''}>
              <FormInput placeholder={question.placeholder} name="answer" autoComplete='off' maxLength={1000}/>
            </div>
            <div className={style.controls}>
              {isLast ? (
                <Button type="submit" color="accent">
                  Generate
                </Button>
              ) : (
                <Button type="submit">
                  Next <ArrowRight className={style.icon} />
                </Button>
              )}
              {question.key !== 'productName' && (
                <div className={style.prevBtnWrapper}>
                  <LinkButton onClick={onPrevious} color="secondary">
                    <ArrowLeft className={style.iconLeft} /> Previous
                  </LinkButton>
                </div>
              )}
            </div>
          </Form>
        </Formik>
      )}
    </div>
  );
};

export default Question;
