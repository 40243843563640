export const CANCEL_IMAGE_GENERATION = {
  operationName: "cancelImageGeneration",
  query:
    `mutation cancelImageGeneration(
      $id: String!
    ) {
      cancelImageGeneration(
        id: $id
      ) {
        message
      }
    }`,
}