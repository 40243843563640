import React, {useState, useEffect, useRef} from 'react';
import s from './AttributeImage.module.scss';
import Button from '../../Components/Common/Button/Button';
import { eventEmiter } from '../../rx/rxState';
import { getUserId } from '../../utils/api';
import { PAGECRAFT_API_URL } from '../../Constants';

const AttributeImage = (props) => {
    const [value, setValue ] = useState(props.attribute.value);

    const inputFileRef = useRef(null) 

    const onChange = (v) =>{
        let attr = props.attribute;
        props.onChange(attr.id, v);
        setValue(v)

        eventEmiter.next({
            type: 'update-view'
          })
    }

    const handleImageSet = () =>{
        inputFileRef.current.click();
    }

    const handleFileLoad = () =>{
        const files = inputFileRef.current.files
        let file = files[0];
        if (file) {
            const reader = new FileReader()
            reader.readAsDataURL(file);
            reader.onloadend = ((res) => {
                const content = reader.result;
                onChange( content )
            });

            uploadFile( file );
        }
    }

    const uploadFile = (file) =>{
        const formData  = new FormData();
        formData.append("field", JSON.stringify({ id: getUserId() })); //TODO: need to set proper USER ID here
        formData.append("file", file);

        fetch(`${PAGECRAFT_API_URL}/images`, {
            method: "POST",
            body: formData
        })
        .then(
            r => r.json()
        )
        .then( data => {
            onChange( data.url )
        })
    }

    const handleImageRemove = () =>{
            onChange( '' )
    }
    return (
        <div className={s.container}>
            <Button
                color="attribute"
                size='attribute'
                onClick={handleImageSet}
                >
                Set Image
            </Button>
            <input
                type="file"
                style={{display: 'none'}}
                accept="image/*"
                ref={inputFileRef}
                onChange={handleFileLoad}
                />
            {props.attribute.value && <Button
                color="attribute"
                size='attribute'
                onClick={handleImageRemove}
                >
                Remove Image
            </Button>}
        </div>
    );
}

export default AttributeImage;