import React from 'react';
import s from './InputWithPrefix.module.scss'

function InputWithPrefix(props) {

    return (
        <>
            <label className={s.label}>{props.label}</label>
            <div className={s.container}
                 style={{outline: `${(props.isErrorActive && !props.isInputFocused) ? '1px solid #FF5E5E' : 'none'}`}}>
                <p className={s.prefix}>{props.prefix}</p>
                <input autoComplete={0} value={props.value}
                       style={{width: `${props.width}px`}}
                       onChange={(e) => {
                           props.setState(e.currentTarget.value)
                       }}
                       onKeyPress={(e) => {
                           (e.key === "Enter") && props.handleSubmit(e.currentTarget.value)
                       }}
                       onFocus={(e) => {
                           e.stopPropagation()
                           props.setIsInputFocused(true)
                       }}
                       onBlur={(e) => {
                           props.setIsInputFocused(false)
                           props.handleSubmit(e.currentTarget.value)
                       }}
                />
            </div>
        </>
    );
}

export default InputWithPrefix;
