import React from 'react';
import s from './StripeInput.module.scss';

interface IProps {
  name: string;
  placeholder: string;
  onChange: (newValue: string) => void;
  value: string;
}

const StripeInput = (props: IProps) => {
  return <div className={s.inputBlock}>
    <label>
      <span className={s.placeholder}>{props.placeholder}</span>
      <input
        name={props.name}
        placeholder={props.placeholder}
        className={s.input}
        value={props.value}
        onChange={(event) => props.onChange(event.target.value)}
      />
    </label>
  </div>
}

export default StripeInput;