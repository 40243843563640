import React from 'react';
import styled from 'styled-components';
import Tooltip from 'Components/Common/Tooltip/Tooltip';

const ExitModalButtonsStyled = styled.span`
  display: inline-block;


  overflow: hidden;

  border: 0px #c6cacf solid;

  color: #000;

  > label {
    cursor: pointer;
    display: inline-block;
    height: 30px;
    background: white;
    border-radius: 5px 0px 0px 5px;

    > input {
      display: none;
    }

    > input + div {
    }

    &:first-of-type {
      margin-right: 1px;
      div{
        border-radius: 5px 0px 0px 5px;

        span{
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
        }
      }
    }
    &:last-of-type  {
      position: relative;
      top: 1px;
      border-radius: 0px 5px 5px 0px;
      div{
        border-radius: 0px 5px 5px 0px;
        span{
          svg{
            position: relative;
            top: 3px;
          }
        }
      }
    }

    &:last-of-type > input + div {
      width: 30px;
    }

    > input:checked + div {
      background: #000000;
      // border-color: #067fff;
      
      > span {
        color: #fff;
      }
    }

    &:last-of-type > input:checked + div {
      background: #ffffff;
    }

    > div {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
    }
  }
`;

const ExitModalButtons = (props) => {

  const handleChangeEnabled = (e) => {
    props.onChangeEnabled(e.target.checked);
  }

  const handleChangeView = (e) => {
    props.onChangeView(e.target.checked);
  }

  return (
    <ExitModalButtonsStyled>
      <Tooltip text='Turn on/off exit intent popup'>
        <label>
          <input
            type="checkbox"
            checked={props.isModalEnabled}
            onChange={handleChangeEnabled}
          />
          <div>
            <span>Pop Up</span> 
          </div>
        </label>
      </Tooltip>
      <Tooltip text='Edit popup'>
      <label>
        <input
          type="checkbox"
          checked={props.isModalOpened}
          onChange={handleChangeView}
        />
        <div>
          <span>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2105_14429)">
            <path d="M12.5 0C11.53 0 10.66 0.39 10.03 1.03L0 11V16H5L14.97 5.97C15.61 5.34 16 4.47 16 3.5C16 1.57 14.43 0 12.5 0ZM4.59 15H1V11.41C1 11.41 7.51 4.94 9.88 2.58L13.42 6.12C11.06 8.49 4.59 15 4.59 15ZM14.27 5.27C14.26 5.28 14.2 5.34 14.12 5.42L10.58 1.88C10.66 1.8 10.71 1.75 10.73 1.73C11.19 1.28 11.81 1 12.5 1C13.88 1 15 2.12 15 3.5C15 4.19 14.72 4.81 14.27 5.27Z" fill="black"/>
            </g>
            <defs>
            <clipPath id="clip0_2105_14429">
            <rect width="16" height="16" fill="white"/>
            </clipPath>
            </defs>
          </svg>
          </span>
        </div>
      </label>
      </Tooltip>
    </ExitModalButtonsStyled>
  );
};

export default ExitModalButtons;
