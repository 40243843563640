

import React from 'react';

import { ErrorMessage, useField, useFormikContext } from "formik"
import style from './FieldWraper.module.scss';

export interface FieldWrapperProps {
  label?: string | React.ReactElement;
  error?: string | object;
  hint?: string | React.ReactElement;
  id: string;
  className: string;
  showErrorMessage?: boolean;
}

export interface FieldProps {
  onChange?: (e: any) => void;
  onBlur?: (e?: any) => void;
  value?: any;
  name?: string;
  error?: string;
}

export interface FormFieldProps {
  name: string;
}

interface FormFieldOptions {
  blurOnchange?: boolean;
  changeEvent?: boolean;
}

export function fieldWrapper<T extends FieldProps>(Component: React.FC<T>, opts: FormFieldOptions = {}) {
  const FormField: React.FC<T & FormFieldProps> = (props: T & FormFieldProps) => {
    const { setFieldValue, errors, setFieldTouched, validateOnBlur } = useFormikContext();
    const [field] = useField(props.name);

    const onChange = (e: any) => {
      const newValue = opts.changeEvent ? e.target.value : e;
      setFieldValue(field.name, newValue);
      if (typeof props.onChange == 'function') {
        props.onChange(newValue);
      }
      if (opts.blurOnchange) {
        setFieldTouched(field.name, validateOnBlur);
      }
    }

    const onBlur = (e: any) => {
      setFieldTouched(field.name, validateOnBlur);
      if (typeof props.onBlur == 'function') {
        const value = opts.changeEvent ? e.target.value : e;
        props.onBlur(value);
      }
    }

    let value = '';
    if (typeof field.value !== 'undefined' && field.value !== null ) {
      value = field.value;
    } else if (typeof props.value !== 'undefined' && props.value !== null ) {
      value = props.value;
    }

    return <><Component
      error={(errors as any)[field.name]}
      {...props}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
    <p className={style.error}><ErrorMessage name={field.name} /></p>
    </>
  }

  FormField.displayName = `FormikWrapper(${Component.displayName})`;

  return FormField;
}
fieldWrapper.displayName = 'FormikWrapper';

