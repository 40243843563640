export const AF_CREATE_CHECKOUT_SESSION = {
  operationName: "afCreateCheckoutSession",
  query:
    `mutation afCreateCheckoutSession(
      $email: String!
      $priceId: String!
      $lm_data: String
    ) {
      afCreateCheckoutSession(
        email: $email
        priceId: $priceId
        lm_data: $lm_data
      ) {
        sessionUrl
      }
    }`,
}