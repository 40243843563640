export const ADD_BLANK_FUNNEL_MUTATION = {
  operationName: "addBlankFunnel",
  query:
    `mutation addBlankFunnel($name: String!) {
      addBlankFunnel(name: $name) {
        description
        name
        title
        _id
        archived
        mailchimpAPI
        mailchimpAudienceId
        mailchimpUsername
        domain
        headerScript
        footerScript
        stripePublicKey
        stripeSecretKey
        customSubDomain,
        pages {
          _id
        }
      }
    }`,
}