import React, {useEffect, useState} from 'react';
import { eventEmiter } from '../rx/rxState';
import ColorSelector from '../Components/ColorSelector';
import {ContainerFlat} from './Styles'

const AttributeColor = (props) => {
  const [value, setValue ] = useState("");

  const onChange = (color) => {
    let attr = props.attribute;
    props.onChange(attr.id, color);
    setValue(color);
    eventEmiter.next({
      type: 'update-view'
    })
  };

  useEffect(() =>{
    let value = props.attribute.value;
    if(value === null){
      value = '#eaedef';
    }
    setValue(value);

  })

  return (
    <ContainerFlat>
      <div style={{margin:'auto'}}>
        <ColorSelector onChange={onChange} color={value}/>
      </div>
    </ContainerFlat>
  );
};

export default AttributeColor;
