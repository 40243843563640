import React, {useState} from 'react';
import * as calendar from './calendarCalculations';
import arrow from '../../../../Assets/icons/arrow.svg'
import './Calendar.module.scss';
import s from './Calendar.module.scss'
import {getDaysInMonth, getMonthStartsOn} from "./calendarCalculations";

const Calendar = (props) => {

    const [date, setDate] = useState(props.date)
    const [currentDate, setCurrentDate] = useState(new Date())
    const [selectedDate, setSelectedDate] = useState(props.selectedDate)

    const handlePrevMonthButtonClick = () => {
        const prevMonth = new Date(date.getFullYear(), date.getMonth() - 1);
        setDate(prevMonth);
    };

    const handleNextMonthButtonClick = () => {
        const prevMonth = new Date(date.getFullYear(), date.getMonth() + 1);
        setDate(prevMonth)
    };

    const handleDayClick = date => {
        setSelectedDate(date)
        props.setDate(date);
        props.onChange(date);
    };

    const {monthNames, weekDayNames} = props;
    const finalDateString = monthNames[date.getMonth()] + ' ' + date.getFullYear();
    const monthData = calendar.getMonthData(date.getFullYear(), date.getMonth(), date.getMonth() - 1);

    const monthStartsOn = getMonthStartsOn(date.getFullYear(), date.getMonth());
    const currMonth = new Date(date.getFullYear(), date.getMonth());
    const daysInMonth = getDaysInMonth(currMonth);

    return (
        <div className={s.calendar}>
            <header>
                <button onClick={handlePrevMonthButtonClick}><img src={arrow} alt={'left'}/></button>
                <p>{finalDateString}</p>
                <button onClick={handleNextMonthButtonClick}><img src={arrow} alt={'right'}/></button>
            </header>

            <table style={{borderSpacing: '5px 2px'}}>
                <thead>
                <tr>
                    {weekDayNames.map(name =>
                        <th key={name}>{name}</th>
                    )}
                </tr>
                </thead>
                <tbody>
                {monthData.map((week, index) =>
                    <tr key={index} className={s.week}>
                        {week.map((date, innerIndex) => date ?
                            <td
                                key={innerIndex}
                                className={`
                                ${s.day} 
                                ${calendar.areEqual(date, currentDate) && s.today} 
                                ${calendar.areEqual(date, selectedDate) && s.selected}
                                ${((index === 0) && (date < week[monthStartsOn])) && s.prev}
                                ${(date.getMonth() > currMonth.getMonth()) && s.next}
                                `}
                                onClick={() => {
                                    if (index === 0 && date < week[monthStartsOn]) {
                                        handlePrevMonthButtonClick()
                                    }
                                    if (date.getMonth() > currMonth.getMonth()) {
                                        handleNextMonthButtonClick()
                                    }
                                    handleDayClick(date)
                                }}
                            >{date.getDate()}</td>
                            :
                            <td key={index}/>
                        )}
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    );
}

Calendar.defaultProps = {
    date: new Date(),
    years: [2018, 2019, 2020, 2021, 2022],
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    weekDayNames: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
    onChange: Function.prototype
};

export default Calendar;