import { Fragment } from 'react';
import { BlockText, BlockTextProxy } from './BlockText';


export class BlockTextCoverProxy extends BlockTextProxy {

}


export class BlockTextCover extends BlockText {
    constructor(props) {
        super(props);

        this.type = 'TextCover';
        this.width = 800;
        this.position = {x: -15, y:20};
        this.isDraggable = true;

        this.removeAttribute('textClass')
        // let attr = {
        //     id: 'borderRadius',
        //     displayName: 'Border radius',
        //     value: '30px',
        //     type: 'AttributeDropdown',
        //     options: [
        //         {
        //             label: 'Completely round',
        //             value: '30px'
        //         },
        //         {
        //             label: 'Round corners',
        //             value: '10px'
        //         },
        //         {
        //             label: 'Square',
        //             value: '0'
        //         },
        //     ],
        // }
        // this.addAttribute(attr);
        let attr = {
            id: 'color',
            displayName: 'Color',
            value: "#FFFFFF",
            type: 'AttributeColor'
        }
        this.addAttribute(attr);
    }
    unpack(data) {
        super.unpack(data);
        
        // if(data['width']){
        //     this.width = data['width'];
        // }
        if(data['position']){
            this.position = data['position'];
        }
    }

    pack() {
        let data = super.pack();
        data['width'] = this.width;
        data['position'] = this.position;
        return data;
    }

    style() {
        let output = super.style();
        if (this.color.value) {
            output += '; background-color: ' + this.color.value + '30;'; //30 is HEX alpha component
        }
        // if (this.borderRadius && this.borderRadius !== undefined) {
        //     output += `; border-radius: ${this.borderRadius.value};`
        // }

        output += `
            position: absolute;
            left: ${this.position.x}px;
            top: ${this.position.y}px;
            width: ${this.width }px;
            backdrop-filter: blur(40px);
        `
        return output;
    }

    renderView() {
        return (
          <Fragment key={this.id}>
          { this.isVisible ?
            <>
                <BlockTextCoverProxy
                id={this.id}
                key={`${this.id}`}
                view={this.view}
                width={this.worldRenderBRect ? this.worldRenderBRect.width : 0}
                content={this.text.editorState}
                styleMap={this.text.styleMap}
                onChange={this.onChange}
                editingHandle={this.editingHandle.bind(this)} //TODO: seems like we can remove it
                contentHandle={this.textEditorStateHandle.bind(this)} //TODO: seems like we can remove it
                contentUpdate={this.contentUpdate.bind(this)}
                reference={this.ref}
                ref={this.proxyRef}
                selected={this.isSelected}
                styleText={this.style}
                className={this.className + this.textClass.value}
                />
            </>
          :
          null }
          </Fragment>
        );
      }
}

