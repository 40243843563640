import React from 'react';
import s from './RerollAiButton.module.scss';

interface IProps {
  x: number;
  y: number;
  onSubmit: () => void;
}

const RerollAiButton = (props: IProps) => {

  return (
    <button
      className={s.button}
      onClick={() => props.onSubmit()}
      style={{top: props.y, left: props.x}}
    >
      <span>
        AI
      </span>
    </button>
  )
}

export default RerollAiButton;