import React, { useState } from 'react';
import s from './Input.module.scss';

interface IProps {
  value: string;
  onChange: (newValue: string) => void;
  label: string;
  hasErrors: boolean;
  autoComplete?: 'on' | 'off';
  maxLength?: number;
}

const Input = (props: IProps) => {
  const [error, setError] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (props.maxLength !== undefined && value.length > props.maxLength) {
      setError(true);
    } else {
      setError(false);
      props.onChange(value);
    }
  };

  return (
    <div className={s.inputWrapper}>
      <input
        placeholder={props.label}
        value={props.value}
        onChange={handleChange}
        className={s.input + ' ' + ((props.hasErrors || error) ? s.error : '')}
        autoComplete={props.autoComplete ? props.autoComplete : 'on'}
      />
      {error && props.maxLength && <div className={s.errorText}>Maximum characters {props.maxLength}</div>}
    </div>
  );
};

export default Input;